import { addEventDelegate } from "../premmio/public/modules/eventDelegate/eventDelegate.js";
import { xhrFormRecaptcha } from "../premmio/public/modules/xhr/xhr.js";

const submitContactForm = (form) => {
  xhrFormRecaptcha({ form });
};

addEventDelegate("submit", "#contact", submitContactForm, true);

const btnMouseEnter = (button) => {
  const now = Date.now();

  const span = button.querySelector("span[aria-hidden]");

  button.dataset.hoverStartTime = now;
  span.style.animationName = "increaseLetterSpacing";
  span.style.animationPlayState = "running";
};

const btnMouseLeave = (button) => {
  const span = button.querySelector("span[aria-hidden]");

  const hoverDuration = Date.now() - parseInt(button.dataset.hoverStartTime);

  let currentLetterSpacing;

  // Check if the hover duration is within the initial jump and hold period
  if (hoverDuration < 250) {
    currentLetterSpacing = 0.5;
  } else if (hoverDuration < 2000) {
    currentLetterSpacing = 1;
  } else {
    // Calculate the effective animation time (excluding the initial 3.5 seconds)
    const effectiveAnimationTime = hoverDuration - 2000;
    // Calculate the percentage of completion for the gradual increase phase
    const percentageComplete = effectiveAnimationTime / (100 * 1000 - 2000);
    // Calculate the current letter spacing, adjusting for the initial 1px
    currentLetterSpacing = 1 + 499 * percentageComplete; // 500px target - 1px initial
  }

  span.style.animationName = "none"; // Stop the increaseLetterSpacing animation
  span.style.letterSpacing = `${currentLetterSpacing}px`;
  span.style.transition = "letter-spacing 0.25s ease";

  setTimeout(() => {
    span.style.letterSpacing = ".5px";
  }, 50);

  // Reset the transition after it's done to avoid affecting other style changes
  setTimeout(() => {
    span.style.transition = "";
  }, 300);
};

const setScrolledValue = (scrollPos) => {
  const threshold = 100,
    header = document.querySelector("header");

  if (scrollPos > threshold) {
    header.classList.add("scrolled");
  } else {
    header.classList.remove("scrolled");
  }
};

addEventDelegate("mouseover", ".btn", btnMouseEnter);
addEventDelegate("mouseout", ".btn", btnMouseLeave);
addEventDelegate("scroll", window, setScrolledValue);
